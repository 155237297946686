import { useCallback } from 'react';

export const useArrayManager = (setFormData, arrayName) => {
  const addItem = useCallback((newItem) => {
    setFormData(prevState => ({
      ...prevState,
      [arrayName]: [...prevState[arrayName], newItem]
    }));
  }, [setFormData, arrayName]);

  const removeItem = useCallback((index) => {
    setFormData(prevState => ({
      ...prevState,
      [arrayName]: prevState[arrayName].filter((_, idx) => idx !== index)
    }));
  }, [setFormData, arrayName]);

  const updateItem = useCallback((index, updatedItem) => {
    setFormData(prevState => ({
      ...prevState,
      [arrayName]: prevState[arrayName].map((item, idx) => idx === index ? updatedItem : item)
    }));
  }, [setFormData, arrayName]);

  const updateItemField = useCallback((index, field, value) => {
    setFormData(prevState => ({
      ...prevState,
      [arrayName]: prevState[arrayName].map((item, idx) => {
        if (idx === index) {
          return { ...item, [field]: value };
        }
        return item;
      })
    }));
  }, [setFormData, arrayName]);

  return { addItem, removeItem, updateItem, updateItemField };
};