// services/productService.js
import { doc, collection, setDoc, updateDoc, getDoc } from "firebase/firestore";

export const initialState = {
  title: '',
  slug: '',
  thumb: '',
  original_link: '',
  is_bundle: false,
  is_visible: true,
  is_colletive: false,
  original_value: 0,
  preco_brasil: 0,
  rate: 0,
  colletive_status: false,
  status: 'published',
  colletive_buyers: [],
  author: {
    nickname: '',
    profile: '',
    profile_photo: '',
  },
  details: [],
  download_items: []
};

export const detailsObj = {
  content: '',
  type: 'text',
  status: true
};

export const downloadObj = {
  link: '',
  thumb: '',
  title: '',
  type: 'png',
  status: true
};

export const addProduct = async (db, productData) => {
  const docRef = doc(collection(db, "acervuz", "teste", "produtos"));
  await setDoc(docRef, productData);
};

export const loadProductById = async (db, productId) => {
  const docRef = doc(db, "acervuz", "teste", "produtos", productId);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return { id: docSnap.id, ...docSnap.data() };
  } else {
    console.log("Produto não encontrado.");
    return null;
  }
};

export const updateProduct = async (db, productId, productData) => {
  const docRef = doc(db, "acervuz", "teste", "produtos", productId);
  await updateDoc(docRef, productData);
};