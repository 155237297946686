import { Container, Row, Col } from "react-bootstrap";
import { Routes, Route } from "react-router-dom";
import { UserAuthContextProvider } from "./context/userAuthContext";
import "./App.css";
import Home from "./components/Home";
import Fornecedores from "./components/Fornecedores";
import Login from "./components/Login";
import Signup from "./components/Signup";
import ProtectedRoute from "./components/ProtectedRoute";
import Fornecedor from "./components/Fornecedor";
import Categorias from "./components/Categorias";
import Produto from "./components/Produto";
import CadastrarProduto from "./components/CadastrarProduto";
import EditarProduto from "./components/EditarProduto";

function App() {
  return (
    <Container className="global-content">
      <Row>
        <Col>
          <UserAuthContextProvider>
            <Routes>
              <Route path="/home" element={ <ProtectedRoute><Home /></ProtectedRoute> } />
              <Route path="/fornecedores" element={ <ProtectedRoute><Fornecedores /></ProtectedRoute> } />
              <Route path="/fornecedor/:slug" element={ <ProtectedRoute><Fornecedor /></ProtectedRoute> } />
              <Route path="/produto/:slug" element={ <ProtectedRoute><Produto /></ProtectedRoute> } />
              <Route path="/categorias/:categoria" element={ <ProtectedRoute><Categorias /></ProtectedRoute> } />
              
              <Route path="/cadastrar-produto" element={ <ProtectedRoute><CadastrarProduto /></ProtectedRoute> } />
              <Route path="/editar-produto/:productId" element={ <ProtectedRoute><EditarProduto /></ProtectedRoute> } />
              <Route path="/" element={<Login />} />
              <Route path="/signup" element={<Signup />} />
            </Routes>
          </UserAuthContextProvider>
        </Col>
      </Row >
    </Container >
  );
}

export default App;