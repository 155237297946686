import React from 'react';

const StarsRatio = ({ ratio }) => {
  const stars = Array.from({ length: 5 }, (_, i) => {
    if (i < Math.floor(ratio)) {
      // Se a estrela está completamente preenchida
      return 'full';
    } else if (i === Math.floor(ratio) && ratio % 1 !== 0) {
      // Se é uma meia estrela (para números fracionários)
      return 'half';
    } else {
      // Se a estrela está vazia
      return 'empty';
    }
  });

  return (
    <ul className="rate-stars">
      {stars.map((type, i) => {
        let starImage;
        if (type === 'full') {
          starImage = 'https://acervuz.com/assets/star.svg';
        } else if (type === 'half') {
          starImage = 'https://acervuz.com/assets/star-half.svg'; // Substitua pelo link da imagem de meia estrela
        } else {
          starImage = 'https://acervuz.com/assets/star-empty.svg';
        }

        return (
          <li key={i}>
            <img
              src={starImage}
              width="16"
              height="16"
              alt=""
            />
          </li>
        );
      })}
    </ul>
  );
};

export default StarsRatio;
