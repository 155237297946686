import { useUserAuth } from "../context/userAuthContext";
import { Link } from "react-router-dom";

import FornecedoresList from "../data/fornecedor.json"
import Menu from "./Menu";

const Fornecedores = () => {
  const { loading } = useUserAuth();

  if (loading) {
    return <p>Carregando...</p>
  }

  return (
    <div className="flex-column p-4 box mt-3 text-center gap-4">
      <Menu />
      <div className="d-grid gap-2">
        {
          FornecedoresList.length > 0 ?
            FornecedoresList?.map((item, key) => {
              const { name, products, slug } = item
              return (
                <div key={key} className="fornecedor-card">
                  <div className="thumb" style={{
                    backgroundImage: `url(https://www.vaviestampas.com.br/assets/images/logo-vavi-estampas-final-fundo-preto.svg)`
                  }}></div>
                  <section>
                    <h3>{name}</h3>
                    <p>{products.length > 0 ? `Total de ${products.length} produtos` : "Nenhum produto"}</p>
                    <Link to={`/fornecedor/${slug}`}>Acessar</Link>
                  </section>
                </div>
              )
            })
            : <p>Nenhum cadastro</p>
        }
      </div>
    </div>
  );
};

export default Fornecedores;



