import React from 'react';
import { TextField, NumberField, CheckboxField, RadioGroupField } from './FormFields';

export const FormBuilder = ({ config, onChange }) => {
  const { type, name, label, value, options, checked, max, min, listConfig } = config;

  switch (type) {
    case 'text':
      return (
        <TextField
          label={label}
          name={name}
          value={value}
          onChange={onChange}
        />
      );

    case 'number':
      return (
        <NumberField
          label={label}
          name={name}
          value={value}
          max={max}
          min={min}
          onChange={onChange}
        />
      );

    case 'checkbox':
      return (
        <CheckboxField
          label={label}
          name={name}
          checked={checked}
          onChange={onChange}
        />
      );

    case 'radioGroup':
      return (
        <RadioGroupField
          label={label}
          name={name}
          options={options}
          value={value}
          onChange={onChange}
        />
      );

    case 'fake':
      return (
        <div className="fake-input">
          <label>{label}:</label>
          <div>{value}</div>
        </div>
      )

      case 'two-columns':
        return (
          <div className="control-two-columns">
            {config.childs.map((childConfig, index) => {
              if (childConfig.type === 'checkbox') {
                return (
                  <CheckboxField
                    key={`${config.name}-child-${index}`}
                    label={childConfig.label}
                    name={childConfig.name}
                    checked={childConfig.checked}
                    onChange={onChange}
                  />
                );
              } else if (childConfig.type === 'number') {
                return (
                  <NumberField
                    key={`${config.name}-child-${index}`}
                    label={childConfig.label}
                    name={childConfig.name}
                    max={childConfig.max}
                    min={childConfig.min}
                    value={childConfig.value}
                    onChange={onChange}
                  />
                );
              }
              return null; // Adicione um retorno padrão para evitar o erro
            })}
          </div>
        );

        case 'list':
          return (
            <div className="down-list-record">
              <h6>{label}</h6>
              {value && value.map((item, index) => (
                <div key={`${name}-${index}`} className="item"> {/* Garantir chave única aqui */}
                  {listConfig.fields.map((fieldConfig, fieldIndex) => {
                    const isCheckbox = fieldConfig.type === 'checkbox';
                    const checkboxValue = isCheckbox ? item[fieldConfig.name] : undefined;
                    return <FormBuilder
                      key={`${name}-${index}-${fieldIndex}-${fieldConfig.name}`} /* Chave única para cada campo */
                      config={{
                        ...fieldConfig,
                        value: isCheckbox ? undefined : item[fieldConfig.name],
                        checked: checkboxValue,
                        name: `${name}[${index}].${fieldConfig.name}`
                      }}
                      onChange={(e) => listConfig.onItemChange ? listConfig.onItemChange(index, fieldConfig.name, isCheckbox ? e.target.checked : e.target.value) : null}
                    />
                  })}
                  <button type="button" className="remove" onClick={() => listConfig.onRemoveItem ? listConfig.onRemoveItem(index) : null}>Remover</button>
                </div>
              ))}
              <button type="button" className="comprar" onClick={() => listConfig.onAddItem ? listConfig.onAddItem(listConfig.newItem) : null}>Adicionar Item</button>
            </div>
          );
      


    // Outros tipos de campo conforme necessário...

    default:
      return null;
  }
};