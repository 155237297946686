import React, { useState, useEffect, useCallback } from "react";
import { useUserAuth } from "../context/userAuthContext";
import { Link, useParams } from "react-router-dom";

import { Currency } from "../helpers";
import Menu from "./Menu";

import { collection, query, where, getDocs, doc, getDoc } from "firebase/firestore";
import StarsRatio from "./StarsRatio";

const dollarValue = 4.80;

const isInteger = (number) => {
  const isInteger = Number.isInteger(number);
  return isInteger;
}

const Produto = () => {
  const { db, userId, loading, userRules } = useUserAuth();
  const { slug } = useParams();
  const [ fornecedor, setFornecedor ] = useState([]);
  const [ loadingInfos, setLoadingInfos ] = useState(true);

  const verifyContas = useCallback(async () => {
    const docRef = query(collection(db, "acervuz/teste/produtos"), where("slug", "==", slug));
    const docSnap = await getDocs(docRef);
  
    if (!docSnap.empty) {
      const firstDoc = docSnap.docs[0]; // Supondo que o slug é único
      const produtoData = { id: firstDoc.id, ...firstDoc.data() };
      const hasAccess = await checkAccess(db, userId, firstDoc.id);

      setFornecedor({ ...produtoData, hasAccess });
      setLoadingInfos(false);
    } else {
      console.log("No such document!");
    }
  }, [db, slug, userId]);

  useEffect(()=> {
    verifyContas();
  },[verifyContas]);

  // Verifica se o usuário tem acesso ao produto e se o acesso é true
  const checkAccess = async (db, userId, productId) => {
    const userProdRef = doc(db, `users/${userId}/produtos_acessiveis/${productId}`);
    const docSnap = await getDoc(userProdRef);

    if (docSnap.exists()) {
      const data = docSnap.data();
      return data.access === true; // Verifica se o campo access é true
    }
    return false; // Retorna false se o documento não existir ou se access não for true
  };
  
  const {
    title,
    thumb,
    original_value,
    preco_brasil,
    is_bundle,
    is_colletive,
    gallery,
    download_items,
    hasAccess,
    is_visible,
    rate,
    details,
    id
  } = fornecedor;
  
  const priceInBRL = original_value * dollarValue;
  const rating = rate > 0 ? <small className="txt-under" style={{ opacity: 0.5 }}>({rate} Avaliações)</small> : <small className="txt-under" style={{ opacity: 0.5 }}>(nenhuma avaliação)</small>;
  const showRatiosNumber = isInteger(rate) ? rate.toFixed(1) : rate;

  if(loading) {
    return <p>Carregando...</p>
  }
  
  if(!is_visible) {
    return <p>O produto não esta mais disponível</p>
  }

  return (
    <div className="flex-column text-center">
      <Menu />
      <div className="d-grid gap-2">
        <div className="product-info">

          <div className="complete">
            <img src={thumb} alt="" />
            {
              is_bundle && gallery &&
              <div className="thumbs-gallery-bundle-itens">
                {
                gallery.length > 0 ? (
                  gallery?.map((item,key) => {
                    const { thumb, title } = item;
                    return <img key={key} title={title} src={thumb} alt="" className="thumb-bunlde-item" />
                  })
                ) : null }
              </div>
            }
          </div>
          <div className="col-right">

            <div className="price">
            {
              userRules === 'admin' ? (
                <Link to={`/editar-produto/${id}`} className="btn-editar-produto">Editar</Link>
              ) : null
            }
              <div className="flex-alc" style={{ gap: '8px', margin: '0 0 .5rem 0' }}>
                <strong style={{ fontSize: '18px'}}>{showRatiosNumber}</strong>
                <StarsRatio ratio={rate} />
                {rating}
              </div>
              <div>
                { !loadingInfos && <h1>{title}</h1> }
                { !loadingInfos && !hasAccess && <strong>{Currency.toBRL(preco_brasil ? preco_brasil : priceInBRL)}</strong> }
                { details.length > 0 ?
                  <div>
                  {details?.map((detail, key) => {
                    const { content, type, status } = detail;
                    let element;

                    if(!status) return false;

                    if (type === 'text') {
                      element = <p key={key}>{content}</p>
                    } else if (type === 'obs') {
                      element = <p key={key}><b>Observação:</b><br/> {content}</p>
                    }

                    return element;
                  })}
                  </div> :
                  <p style={{ marginTop: '0.5rem'}}>Nenhum detalhe informado</p> }
              </div>
              
              {
                hasAccess && download_items && download_items.length > 0 ? (
                  <ul className="download-list">
                    {
                      download_items?.map((itemToDownload,key)=> {
                        const { link, thumb, title } = itemToDownload;
                        return (
                          <li key={key}>
                            <div>
                              <span className="thumb" style={{ backgroundImage: `url('${thumb}')`}}></span>
                              <h4>{title}</h4>
                            </div>
                            <a href={link} target="_blank" rel="noreferrer" className="comprar">Download</a>
                          </li>
                        )
                      })
                    }
                  </ul>
                ) : null
              }
             
              { !hasAccess && <><button className="comprar">Comprar</button><br/></> }

              <small style={{ opacity: 0.5, marginTop: '0.5rem', display: 'block' }}>ID do produto: {id}</small>
            </div>

            {
              is_colletive &&
              <div className="coletivo-item">
                <h3>Item para compra coletiva:</h3>
                <p>Ao adquirir a compra coletiva, você tem acesso vitalício aos arquivos que compõem a compra. Pagando um valor muito abaixo do valor de mercado.</p>
              </div>
            }

            {/* {
              is_bundle &&
              <div>
                <h3>Itens relacionados ao pacote:</h3>
              </div>
            } */}

          </div>
        </div>

      </div>
    </div>
  );
};

export default Produto;