import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useUserAuth } from "../context/userAuthContext";
import { Currency } from "../helpers";
import Menu from "./Menu";

import {
  collection,
  query,
  onSnapshot,
  orderBy
} from "firebase/firestore";

const Home = () => {
  const { db, userId, loading } = useUserAuth();
  const [ produtos, setProdutos ] = useState([]);

  const verifyContas = useCallback(async () => {
    if (userId) {
      onSnapshot( query( collection(db, "acervuz", "teste", "produtos") , orderBy("registerDate", "asc")) ,(snapshot)=>{
        setProdutos(snapshot.docs.map((com) => ({
          ...com.data(), id: com.id
        })));
      });    
    }
  }, [db, userId]);

  useEffect(()=> {    
    if (!loading) {
      verifyContas();
    }
  },[verifyContas, loading])

  if(loading) {
    return <p>Carregando...</p>
  }

  const dollarValue = 4.80;

  return (
    <div className="flex-column text-center">
      <Menu />
      <div className="grid-items">
      {
        produtos.length > 0 ?
        produtos?.map((item, key) => {
            const {
              title,
              original_value,
              slug,
              thumb,
              preco_brasil,
              is_visible
            } = item;

            const priceInBRL = original_value * dollarValue;

            if(!is_visible) return false;

            return (
              <Link to={`/produto/${slug}`} key={key} className="fornecedor-card">
                <div className="thumb" style={{
                  backgroundImage: `url(${thumb})`
                }}></div>
                <section>
                  <h3>{title}</h3>
                  <strong>{Currency.toBRL(preco_brasil ? preco_brasil : priceInBRL)}</strong>
                </section>
              </Link>
            )
          })
          : <p>Nenhum cadastro</p>
      }
      </div>

    </div>
  );
};

export default Home;



