// components/CadastrarProduto.js
import React, { useState } from "react";
import { useUserAuth } from "../context/userAuthContext";

import Menu from "./Menu";

import { collection, doc, setDoc, } from "firebase/firestore";
import { Utils } from "../helpers";

const initialState = {
  title: '',
  slug: '',
  thumb: '',
  original_link: '',
  is_bundle: false,
  is_visible: true,
  is_colletive: false,
  original_value: 0,
  preco_brasil: 0,
  rate: 0,
  colletive_status: false,
  status: 'published',
  colletive_buyers: [],
  details: [],
  author: {
    nickname: '',
    profile: '',
    profile_photo: '',
  },
  download_items: []
};

// Um componente para cada campo do formulário, por exemplo:
const TextField = ({ label, name, value, onChange }) => (
  <div>
    <label>{label}:</label>
    <input type="text" name={name} value={value} onChange={onChange} className="input" />
  </div>
);

const NumberField = ({ label, name, value, onChange }) => (
  <div className="full">
    <label>{label}:</label>
    <input 
      type="number" 
      name={name} 
      value={value} 
      onChange={onChange} 
      className="input" 
    />
  </div>
);

const CheckboxField = ({ label, name, checked, onChange }) => (
  <div className="full input-content-checkbox">
    <label>{label}:</label>
    <input 
      type="checkbox" 
      name={name} 
      checked={checked} 
      onChange={onChange} 
      className="input-check"
    />
  </div>
);

// const RadioField = ({ label, name, value, checked, onChange }) => (
//   <div>
//     <label>{label}:</label>
//     <input 
//       type="radio" 
//       name={name} 
//       value={value} 
//       checked={checked} 
//       onChange={onChange} 
//       className="input" 
//     />
//   </div>
// );

const RadioGroupField = ({ label, name, options, value, onChange }) => (
  <div>
    <label>{label}:</label>
      <div className="radio-group-download-itens">
      {options.map((option) => (
        <div key={option.value}>
          <input 
            type="radio" 
            name={name}
            value={option.value} 
            checked={value === option.value} 
            onChange={onChange} 
            className="input" 
          />
          {option.label}
        </div>
      ))}
    </div>
  </div>
);

const CadastrarProduto = () => {
  const [formData, setFormData] = useState(initialState);
  const { db, loading } = useUserAuth();

  // Adicionar item no Firestore
  const adicionarItems = async (e)=> {
    e.preventDefault();
    
    // Verifica se algum campo obrigatório está faltando
    if (!formData.title || !formData.thumb || !formData.original_link) {
      console.error("Campos obrigatórios faltando");
      return; // Sai da função se algum campo obrigatório estiver faltando
    }

    const slug = Utils.titleToSlug(formData.title);

    const productData = {
      registerDate: new Date(),
      title: formData.title,
      slug: slug,
      original_link: formData.original_link,
      is_bundle: formData.is_bundle,
      is_visible: formData.is_visible,
      is_colletive: formData.is_colletive,
      original_value: parseFloat(formData.original_value),
      preco_brasil: parseFloat(formData.preco_brasil),
      thumb: formData.thumb,
      status: 'published',
      rate: 0,
      colletive_status: false,
      colletive_buyers: [],
      details: [],
      author: {
        nickname: '',
        profile: '',
        profile_photo: '',
      },
      download_items: formData.download_items
    };

    // console.log(productData);

    if (!formData.title.trim()) {
      console.log('Nome é obrigatório');
    } else if (formData.title.trim().length < 3) {
      console.log('Nome deve ter pelo menos 3 caracteres');
    } 
    else if (!formData.thumb.trim()) {
      console.log('Thumbnail é obrigatório');
    }
    else if (formData.thumb.trim().length < 10) {
      console.log('Thumbnail deve ter pelo menos 10 caracteres');
    }
    else if (formData.download_items.length === 0) {
      console.log('Devemos ter pelo menos 1 item para download');
    }
    else {
      try {
        await setDoc(doc(collection(db, "acervuz", "teste", "produtos")), productData);
        console.log("Produto adicionado com sucesso.");
        setFormData(initialState);
      } catch (error) {
        console.error("Erro ao adicionar produto:", error);
      }  
    }
  }

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value
    }));

  };

  const handleAddDownloadItem = () => {
    setFormData(prevState => ({
      ...prevState,
      download_items: [...prevState.download_items, { link: '', thumb: '', title: '', type: 'png', status: true }]
    }));
  };

  const removeDownloadItem = (index) => {
    setFormData(prevState => ({
        ...prevState,
        download_items: prevState.download_items.filter((_, idx) => idx !== index)
    }));
  };

  const handleDownloadItemChange = (index, field, value) => {
    setFormData(prevState => ({
      ...prevState,
      download_items: prevState.download_items.map((item, idx) => {
        if (idx === index) {
          return { ...item, [field]: value };
        }
        return item;
      })
    }));
  };

  if(loading) {
    return <p>Carregando...</p>
  }

  return (
    <div className="flex-column text-center">
      <Menu />
      <div className="d-grid gap-2">

        <div className="product-info">
          <div className="complete">
            <form onSubmit={adicionarItems} className="form-cad">
              <TextField
                label={"Titulo do Produto"}
                name="title"
                value={formData.title}
                onChange={(e) => handleChange(e)}
              />
              <div className="fake-input">
                <label>Slug:</label>
                <div>{Utils.titleToSlug(formData.title || '')}</div>
              </div>
              <TextField
                label={"Thumbnail do Produto"}
                name="thumb"
                value={formData.thumb}
                onChange={(e) => handleChange(e)}
              />
              <TextField
                label={"Link Original"}
                name="original_link"
                value={formData.original_link}
                onChange={(e) => handleChange(e)}
              />
              <div className="control-two-columns">
                <CheckboxField
                  label="É um Bundle"
                  name="is_bundle"
                  checked={formData.is_bundle}
                  onChange={(e) => handleChange(e)}
                />
                <CheckboxField
                  label="Esta visível"
                  name="is_visible"
                  checked={formData.is_visible}
                  onChange={(e) => handleChange(e)}
                />
                <CheckboxField
                  label="É um coletivo"
                  name="is_colletive"
                  checked={formData.is_colletive}
                  onChange={(e) => handleChange(e)}
                />
                <CheckboxField
                  label="Coletivo finalizado?"
                  name="colletive_status"
                  checked={formData.colletive_status}
                  onChange={(e) => handleChange(e)}
                />
              </div>
              
              <div className="control-two-columns">
                <NumberField
                  label={"Valor Original"}
                  name="original_value"
                  value={formData.original_value}
                  onChange={(e) => handleChange(e)}
                /> 
                <NumberField
                  label={"Valor do Brasil"}
                  name="preco_brasil"
                  value={formData.preco_brasil}
                  onChange={(e) => handleChange(e)}
                /> 
              </div>

              
              <div className="down-list-record">
                {formData.download_items && formData.download_items.map((item, index) => {
                  return <div key={index} className="item">
                    <TextField
                      label={"Titulo"}
                      name="title"
                      value={item.title}
                      onChange={(e) => handleDownloadItemChange(index, 'title', e.target.value)}
                    />                    
                    <TextField
                      label={"Thumbnail"}
                      name="thumb"
                      value={item.thumb}
                      onChange={(e) => handleDownloadItemChange(index, 'thumb', e.target.value)}
                    />
                    <TextField
                      label={"Link"}
                      name="link"
                      value={item.link}
                      onChange={(e) => handleDownloadItemChange(index, 'link', e.target.value)}
                    />
                    <CheckboxField
                      label="É um item ativo?"
                      name="status"
                      checked={item.status}
                      onChange={(e) => handleDownloadItemChange(index, 'status', e.target.checked)}
                    />
                    <RadioGroupField
                      label="Escolha o tipo de arquivo"
                      name={`radioGroup-${index}`}
                      options={[
                        { label: 'PNG', value: 'png' },
                        { label: 'ZIP', value: 'zip' },
                        { label: 'JPG', value: 'jpg' },
                        { label: 'SVG', value: 'svg' },
                        { label: 'PDF', value: 'pdf' },
                      ]}
                      value={item.type}
                      onChange={(e) => handleDownloadItemChange(index, 'type', e.target.value)}
                    />
                    <button type="button" className="remove" onClick={() => removeDownloadItem(index)}>Remover</button>
                  </div>
                })}
                <button type="button" className="comprar" onClick={handleAddDownloadItem}>Adicionar Item</button>
              </div>

              <button type="submit">Cadastrar Produto</button>
            </form>
          </div>
          <div className="col-right">
          </div>
        </div>

      </div>
    </div>
  );
};

export default CadastrarProduto;