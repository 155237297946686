import React from 'react';
import { useParams, Link } from 'react-router-dom';
import fornecedoresList from '../data/fornecedor.json';

const categoriasFornecedores = [
  {
    "title": "Impressão DTF",
    "slug": "impressao-dtf"
  },
  {
    "title": "Sublimação",
    "slug": "sublimacao"
  },
  {
    "title": "Camisetas",
    "slug": "camisetas"
  },
];

const Categorias = () => {
  // Captura o parâmetro da URL
  const { categoria } = useParams();

  // Filtra os fornecedores que contêm a categoria especificada
  const fornecedoresFiltrados = fornecedoresList.filter(fornecedor =>
    fornecedor.fornece.includes(categoria)
  );

  return (
    <div className="categorias">
      <h1>Categoria: {categoria}</h1>
      <div>
        {
          categoriasFornecedores.length > 0 ? 
            categoriasFornecedores?.map((item, key) => {
              return (
                <Link key={key} to={`/categorias/${item.title}`} className={`${categoria === item.title ? 'active' : ''}`}>{item.title}</Link>
              )
            })
          : <p>Nenhuma categoria cadastrada</p>
        }
      </div>
      <div>
        {fornecedoresFiltrados.map((fornecedor, index) => (
          <div key={index} className="fornecedor-card">
            <h2>{fornecedor.name}</h2>
            {/* Outras informações do fornecedor */}
            <Link to={`/fornecedor/${fornecedor.slug}`}>Ver detalhes</Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Categorias;