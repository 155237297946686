// components/CadastrarProduto.js
import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useUserAuth } from "../context/userAuthContext";
import Menu from "./Menu";
import { Utils } from "../helpers";
import { useArrayManager } from '../hooks/useArrayManager';
import { initialState, detailsObj, downloadObj, loadProductById, updateProduct } from '../services/productService';
import { FormBuilder } from '../components/FormBuilder';

const EditarProduto = () => {
  const [formData, setFormData] = useState(initialState);
  const { db, loading } = useUserAuth();
  const { productId } = useParams()

  const { addItem: addDownloadItem, removeItem: removeDownloadItem,
    updateItemField: updateDownloadItemField
  } = useArrayManager(setFormData, 'download_items');
  const { addItem: addDetailsItem, removeItem: removeDetailsItem, updateItemField: updateDetailsItemField } = useArrayManager(setFormData, 'details');
 
  const loadProductData = useCallback(async () => {
    const productData = await loadProductById(db, productId);
    productData && setFormData({ ...productData, download_items: productData.download_items || [], details: productData.details || [] });
  }, [db, productId]);

  useEffect(() => {
    if (productId) {
      loadProductData();
    }
  }, [productId, loadProductData]);


  // Adicionar item no Firestore
  const handleSubmit = async (e)=> {
    e.preventDefault();

    if (!formData.title.trim()) {
      console.log('Nome é obrigatório');
    } else if (formData.title.trim().length < 3) {
      console.log('Nome deve ter pelo menos 3 caracteres');
    } 
    else if (!formData.thumb.trim()) {
      console.log('Thumbnail é obrigatório');
    }
    else if (formData.thumb.trim().length < 10) {
      console.log('Thumbnail deve ter pelo menos 10 caracteres');
    }
    else if (formData.download_items.length === 0) {
      console.log('Devemos ter pelo menos 1 item para download');
    }
    else {

      const updatedFormData = {
        ...formData,
        rate: parseFloat(formData.rate),
        preco_brasil: parseFloat(formData.preco_brasil),
        original_value: parseFloat(formData.original_value),
      };

      try {
        await updateProduct(db, productId, updatedFormData);
        alert('Produto atualizado com sucesso!')
      } catch (error) {
        console.log('Error', error)
      }
    }
  }

  const handleChange = useCallback((e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value
    }));

  }, []);

  if(loading) return <p>Carregando...</p>

  const formFields = [
    { type: 'text', name: 'title', label: 'Nome do Produto', value: formData.title },
    { type: 'fake', label: 'Slug', value: Utils.titleToSlug(formData.title || '') },
    { type: 'text', name: 'thumb', label: 'Thumbnail do Produto', value: formData.thumb },
    { type: 'text', name: 'original_link', label: 'Link Original', value: formData.original_link },
    {
      type: 'two-columns',
      childs: [
        { type: 'checkbox', name: 'is_bundle', label: 'É um Bundle', checked: formData.is_bundle },
        { type: 'checkbox', name: 'is_visible', label: 'Esta visível', checked: formData.is_visible },
      ]
    },
    {
      type: 'two-columns',
      childs: [
        { type: 'checkbox', name: 'is_colletive', label: 'É um coletivo', checked: formData.is_colletive },
        { type: 'checkbox', name: 'colletive_status', label: 'Coletivo finalizado?', checked: formData.colletive_status },
      ]
    },
    {
      type: 'two-columns',
      childs: [
        { type: 'number', name: 'original_value', label: 'Valor Original', min: 0, value: formData.original_value },
        { type: 'number', name: 'preco_brasil', label: 'Valor do Brasil', min: 0, value: formData.preco_brasil },
        { type: 'number', name: 'rate', label: 'Avaliação', max: 5, min: 0, value: formData.rate },
      ]
    },

    {
      type: 'list',
      label: 'Arquivos para Download',
      name: 'download_items',
      value: formData.download_items,
      listConfig: {
        fields: [
          { type: 'text', name: 'title', label: 'Título' },
          { type: 'text', name: 'thumb', label: 'Thumbnail' },
          { type: 'text', name: 'link', label: 'Link' },
          { type: 'checkbox', name: 'status', label: 'É um item ativo?', checked: formData.status },
          {
            type: 'radioGroup',
            name: 'type',
            label: 'Escolha o tipo de arquivo',
            options: [
              { label: 'PNG', value: 'png' },
              { label: 'ZIP', value: 'zip' },
              { label: 'JPG', value: 'jpg' },
              { label: 'SVG', value: 'svg' },
              { label: 'PDF', value: 'pdf' },
            ],
            value: formData.type
          }
        ],
        newItem: downloadObj, // Objeto modelo para novos itens
        onRemoveItem: removeDownloadItem,
        onItemChange: updateDownloadItemField,
        onAddItem: addDownloadItem,
      }
    },
    {
      type: 'list',
      label: 'Detalhes do Produto',
      name: 'details',
      value: formData.details,
      listConfig: {
        fields: [
          { type: 'text', name: 'content', label: 'Conteúdo' },
          { type: 'checkbox', name: 'status', label: 'É um item ativo?', checked: formData.status },
          {
            type: 'radioGroup',
            name: 'type',
            label: 'Escolha o tipo de arquivo',
            options: [
              { label: 'Texto', value: 'text' },
              { label: 'Observação', value: 'obs' },
            ],
            value: formData.type
          }
        ],
        newItem: detailsObj, // Objeto modelo para novos itens
        onAddItem: addDetailsItem,
        onRemoveItem: removeDetailsItem,
        onItemChange: updateDetailsItemField,
      },
    },
  ];

  const renderFormField = (fieldConfig) => {
    return (
      <FormBuilder
        key={fieldConfig.name}
        config={fieldConfig}
        onChange={handleChange}
      />
    );
  };

  return (
    <div className="flex-column text-center">
      <Menu />
      <div className="d-grid gap-2">

        <div className="product-info">
          <div className="complete">
            <form onSubmit={handleSubmit} className="form-cad">
              {
                formFields.map(renderFormField)
              }
              <button type="submit">Atualizar Produto</button>
            </form>
          </div>
          <div className="col-right">
          </div>
        </div>

      </div>
    </div>
  );
};

export default EditarProduto;