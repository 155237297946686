function discountPercentage (valueTotal, discountNum) {
  return ( valueTotal - ( valueTotal * ( discountNum * 0.01 ) ) )
}

function discountValue (valueTotal, discountNum) {
  return ( valueTotal - discountNum )
}

function discountCalc (discountType, valueTotal, discountNum) {
  return ( discountType === 'P' ? discountPercentage(valueTotal, discountNum) : discountValue(valueTotal, discountNum) )
}

function valueProductDiscountPercentage (valueTotal, discountNum) {
  return (valueTotal * (discountNum*0.01) )
}

function slugify(text, separator = "-") {
  return text
          .toString()
          .normalize('NFD')                   // split an accented letter in the base letter and the acent
          .replace(/[\u0300-\u036f]/g, '')   // remove all previously split accents
          .toLowerCase()
          .trim()
          .replace(/[^a-z0-9 ]/g, '')        // remove all chars not letters, numbers and spaces (to be replaced)
          .replace(/\s+/g, separator);
}

function processString (input) {
  const specialCharactersMapping = {
    'ç': 'c',
    'Ç': 'C',
    'ã': 'a',
    'Ã': 'A',
    'õ': 'o',
    'Õ': 'O',
    'á': 'a',
    'Á': 'A',
    'é': 'e',
    'É': 'E',
    'í': 'i',
    'Í': 'I',
    'ó': 'o',
    'Ó': 'O',
    'ú': 'u',
    'Ú': 'U',
    'â': 'a',
    'Â': 'A',
    'ê': 'e',
    'Ê': 'E',
    'î': 'i',
    'Î': 'I',
    'ô': 'o',
    'Ô': 'O',
    'û': 'u',
    'Û': 'U',
    'à': 'a',
    'À': 'A',
    'è': 'e',
    'È': 'E',
    'ì': 'i',
    'Ì': 'I',
    'ò': 'o',
    'Ò': 'O',
    'ù': 'u',
    'Ù': 'U',
    'ü': 'u',
    'Ü': 'U',
    'ë': 'e',
    'Ë': 'E',
    'ï': 'i',
    'Ï': 'I',
    'ö': 'o',
    'Ö': 'O',
    'ÿ': 'y',
    'Ÿ': 'Y',
    'ñ': 'n',
    'Ñ': 'N',
  };

  let processedString = input.toLowerCase();

  // Substituir caracteres especiais por letras específicas
  Object.entries(specialCharactersMapping).forEach(([specialChar, replacement]) => {
    processedString = processedString.replace(new RegExp(specialChar, 'g'), replacement);
  });

  // Remover espaços e barra ("/")
  processedString = processedString.replace(/\s/g, '').replace(/\//g, '');

  return processedString;
}

function titleToSlug (title) {
  // if (!title) return '';

  const map = {
    'ç': 'c', 'ã': 'a', 'á': 'a', 'à': 'a', 'â': 'a', 'ä': 'a', 'é': 'e', 'ê': 'e', 'ë': 'e', 'è': 'e',
    'í': 'i', 'î': 'i', 'ï': 'i', 'ì': 'i', 'ó': 'o', 'ô': 'o', 'ö': 'o', 'ò': 'o', 'õ': 'o', 'ú': 'u',
    'û': 'u', 'ü': 'u', 'ù': 'u', 'ñ': 'n',
    // Adicione mais mapeamentos conforme necessário
  };

  return title.toLowerCase()
  .split('') // Transforma em array de caracteres
  .map(char => map[char] || char) // Substitui caracteres com base no mapa
  .join('') // Junta os caracteres novamente
  .replace(/[\W_]+/g, '-') // Substitui caracteres especiais por hifens
  .replace(/-+/g, '-') // Substitui múltiplos hifens por um único hifen
  .replace(/^-+|-+$/g, ''); // Remove hifens extras do início e do fim
};

export {
  discountPercentage,
  discountValue,
  discountCalc,
  valueProductDiscountPercentage,
  slugify,
  processString,
  titleToSlug
};