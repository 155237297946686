import { Button } from "react-bootstrap";
import { useNavigate } from "react-router";
import { useUserAuth } from "../context/userAuthContext";
import { Link, useParams } from "react-router-dom";

import FornecedoresList from "../data/fornecedor.json"

const Fornecedor = () => {
  const { logOut, user, loading } = useUserAuth();
  const navigate = useNavigate();
  const { slug } = useParams();

  if(loading) {
    return <p>Carregando...</p>
  }
  
  const handleLogout = async () => {
    try {
      await logOut();
      navigate("/");
    } catch (error) {
      console.log(error.message);
    }
  };

  // Filtrar o fornecedor com o slug 'direct-film'
  const fornecedor = FornecedoresList.find(f => f.slug === slug);

  // Verifica se o fornecedor foi encontrado
  if (!fornecedor) {
    return <p>Fornecedor não encontrado</p>;
  }

  const { name, products } = fornecedor;

  return (
    <div className="flex-column p-4 box mt-3 text-center gap-4">
      <div className="p-4 box mt-3 text-center">
        Hello Welcome { user.email }<br />
        <Link to="/home">Home</Link>
        <Button variant="primary" onClick={handleLogout}>
          Log out
        </Button>
      </div>
      <div className="d-grid gap-2">
        <div className="fornecedor-card">
          <div className="thumb" style={{
            backgroundImage: `url(https://www.vaviestampas.com.br/assets/images/logo-vavi-estampas-final-fundo-preto.svg)`
          }}></div>
          <section>
            <h3>{name}</h3>
            {products?.map((product, index) => {
              const {title, value} = product;
              return (
                <div key={index}>
                  <p>{title}</p>
                  <p>R$ {value}</p>
                </div>
              )})
            }
            <Link to="/home">Acessar</Link>
          </section>
        </div>

      </div>
    </div>
  );
};

export default Fornecedor;



