import React from 'react';

export const TextField = ({ label, name, value, onChange }) => (
  <div>
    <label>{label}:</label>
    <input type="text" name={name} value={value} onChange={onChange} className="input" />
  </div>
);

export const NumberField = ({ label, name, value, max, min, onChange }) => (
  <div className="full">
    <label>{label}:</label>
    <input type="number" name={name} value={value} max={max} min={min} onChange={onChange} className="input" />
  </div>
);

export const CheckboxField = ({ label, name, checked, onChange }) => (
  <div className="full input-content-checkbox">
    <label>{label}:</label>
    <input type="checkbox" name={name} checked={checked} onChange={onChange} className="input-check" />
  </div>
);

export const RadioGroupField = ({ label, name, options, value, onChange }) => (
  <div>
    <label>{label}:</label>
    <div className="radio-group">
      {options.map((option, index) => (
        <div key={index}>
          <input type="radio" name={name} value={option.value} checked={value === option.value} onChange={onChange} className="input-radio" />
          <label>{option.label}</label>
        </div>
      ))}
    </div>
  </div>
);

export const RadioField = ({ label, name, value, checked, onChange }) => (
  <div>
    <label>{label}:</label>
    <input 
      type="radio" 
      name={name} 
      value={value} 
      checked={checked} 
      onChange={onChange} 
      className="input" 
    />
  </div>
);