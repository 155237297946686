import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useUserAuth } from "../context/userAuthContext";

const Menu = () => {
  const { logOut, loading, userRules } = useUserAuth();
  const navigate = useNavigate();
  
  const handleLogout = async () => {
    try {
      await logOut();
      navigate("/");
    } catch (error) {
      console.log(error.message);
    }
  };
  
  if(loading) {
    return <p>Carregando...</p>
  }

  return (
    <header>      
      <div className="header-menu">
        <Link to="/home">Home</Link>
        {userRules === 'admin' && <Link to="/cadastrar-produto">Cadastrar Produto</Link> }
        {userRules === 'admin' && <Link to="/fornecedores">Fornecedores</Link> }
      </div>
      <button onClick={handleLogout}>Sair</button>
    </header>
  );
};

export default Menu;
