import { createContext, useContext, useEffect, useState } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";

import { doc, getDoc } from "firebase/firestore";

import { db, auth } from "../firebase-config";

const userAuthContext = createContext();

export function UserAuthContextProvider({ children }) {
  const [user, setUser] = useState({});
  const [loading, setloading] = useState(true);
  const [userId, setUserId] = useState(null);
  const [userRules, setUserRules] = useState(null);

  function logIn(email, password) {
    return signInWithEmailAndPassword(auth, email, password);
  }
  function signUp(email, password) {
    return createUserWithEmailAndPassword(auth, email, password);
  }
  function logOut() {
    setUser({});
    setUserId(null);
    setUserRules(null);
    return signOut(auth);
  }
  function googleSignIn() {
    const googleAuthProvider = new GoogleAuthProvider();
    return signInWithPopup(auth, googleAuthProvider);
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentuser) => {
      setUser(currentuser);
      setUserId(currentuser ? currentuser.uid : '');
      setloading(false);

      // Verifica se tem usuário logado
      if (currentuser) {
        try {
          // Busca as regras do usuário
          const userDoc = await getDoc(doc(db, 'users', currentuser.uid));
          if (userDoc.exists()) {
            const userData = userDoc.data();
            setUserRules(userData.rules || null); // Supondo que as regras estejam armazenadas na chave 'rules'
          } else {
            console.log('Nenhum dado encontrado para o usuário');
          }
        } catch (error) {
          console.error('Erro ao buscar regras do usuário:', error);
        }
      }
    });

    return () => {
      unsubscribe();
    };
  }, [auth, db]);

  return (
    <userAuthContext.Provider
      value={{
        db,
        user,
        userId,
        userRules,
        loading,
        logIn,
        signUp,
        logOut,
        googleSignIn
      }}
    >
      {children}
    </userAuthContext.Provider>
  );
}

export function useUserAuth() {
  return useContext(userAuthContext);
}