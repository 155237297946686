function toBRL (str) {
  return (
    str
  ).toLocaleString(
    'pt-br',
    {
      style: 'currency',
      currency: 'BRL'
    }
  )
}

function toUSD (str) {
  return (
    str
  ).toLocaleString(
    'en-US',
    {
      style: 'currency',
      currency: 'USD'
    }
  )
}

function currencyTypeReturn (val, cur) {
  let curr;

  switch (cur) {
    case 'BRL':
      curr = toBRL(val);
      break;
    case 'USD':
      curr = toUSD(val);
      break;    
    default:
      curr = `${val.toFixed(8)} ${cur}`;
      break;
  }

  return curr;
}

export {
  toBRL,
  toUSD,
  currencyTypeReturn
}